import type { CustomContentProps } from "notistack";
import { SnackbarContent } from "notistack";
import { forwardRef } from "react";

import { Container } from "./styles";

export type SimpleSnackbarItemProps = {
  leftElement: JSX.Element;
  rightElement: JSX.Element;
};
export type Props = SimpleSnackbarItemProps & CustomContentProps;

export const SimpleSnackbarItem = forwardRef<HTMLDivElement, Props>(
  ({ leftElement, rightElement, style }, reference) => (
    <SnackbarContent ref={reference} role="alert" style={style}>
      <Container>
        {leftElement} {rightElement}
      </Container>
    </SnackbarContent>
  ),
);

SimpleSnackbarItem.displayName = "SimpleSnackbarItem";
